import React, { useState, useEffect } from 'react';
import { Row, Button, Col } from 'reactstrap';
// import { colors } from 'config/colors';
import { routes, modals } from 'config/appConfig';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CustomButton = ({ color, label, textColor, arrow, onClick, textSize, disabled, buttonSize, fullWidth, icon, iconCenter, id, timer, modal }) => {

  const [isPressed, setIsPressed] = useState(false);
  const [isTiming, setIsTiming] = useState(timer ? true : false);
  const { pathname } = useLocation();
  const url = pathname;
  const location = useLocation();
  const [delay, setDelay] = useState(timer ? true : false);
  const [timeLeft, setTimeLeft] = useState(false);
  const navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);
  const [isInsideButton, setInsideButton] = useState(false);
  const { languageModalOpen, faqsModalOpen, notificationsModalOpen, loading, theme } = useSelector(state => state.appConfig);

  const detectActivity = () => {
    document.addEventListener('touchstart', animationTimer);
    return () => {
      removeActivityListeners()
    }
  };

  const removeActivityListeners = () => {
    document.removeEventListener('touchstart', animationTimer);
  };

  const handleTouchStart = () => {
    setIsPressed(true);
  }

  const handleTouchEnd = () => {
    if (isInsideButton) {
      setIsTiming(false);
    }
    setIsPressed(false);
  };

  const handleTouchMove = (event) => {
    if (timer) {
      const touch = event.touches[0];
      const touchX = touch.clientX;
      const touchY = touch.clientY;
      const buttonRect = event.touches[0]?.target.getBoundingClientRect();
      if (touchX < buttonRect.left || touchX > buttonRect.right || touchY < buttonRect.top || touchY > buttonRect.bottom) {
        setInsideButton(false)
      }
    }
  };

  const navTo = (url, state) => {
    if (state) {
      handleTransition(url, state)
    }
    else {
      handleTransition(url)
    }
  };

  const animationTimer = () => {
    let delay = false;
    setTimeLeft(0);
    if (modal) {
      modals.map((item) => {
        if (languageModalOpen) {
          if (item?.name === "language") {
            delay = item.inativityTime;
            setDelay(delay * 10);
          }
        }
        if (faqsModalOpen) {
          if (item?.name === "faqs") {
            delay = item.inativityTime;
            setDelay(delay * 10);
          }
        }
        if (notificationsModalOpen) {
          if (item?.name === "notifications") {
            delay = item.inativityTime;
            setDelay(delay * 10);
          }
        }
      })
    }
    else {
      routes.map((item) => {
        if (url === item.path) {
          delay = item.inativityTime;
          setDelay(delay * 10);
        }
      });
    }
  };

  useEffect(() => {
    let id;
    if (isTiming) {
      if (timeLeft < delay) {
        if (!loading && !isPressed) {
          id = setInterval(() => {
            setTimeLeft(timeLeft + 1);
          }, 100);
        }
      }
      if (timeLeft === delay) {
        setIsPressed(true);
        setIsTiming(false);
        navTo('/home')
        setInterval(() => {
          setIsPressed(false);
        }, 100);
      }
    }
    return () => clearInterval(id)
  }, [isTiming, timeLeft, delay, loading, isPressed]);

  useEffect(() => {
    if (modal && timeLeft === delay && location?.pathname === "/home") {
      setIsPressed(true);
      setTimeout(() => {
        setIsPressed(false);
        onClick();
      }, 100);
    }
  }, [modal, timeLeft, location, delay])


  useEffect(() => {
    detectActivity()
    animationTimer()
    return () => {
      removeActivityListeners()
    }
  }, [])

  return (
    <Row className={fullWidth ? 'm-0 p-0 border-radius' : 'border-radius'}>
      <Col xs="12">
        <Row className='border-radius position-relative overflow-hidden' style={{ height: buttonSize === "small" ? "105px" : "130px" }}>
          <Button
            disabled={disabled}
            className={` ${textSize} border-0 border-radius d-flex justify-content-center align-items-center position-relative w-100`}
            style={{ background: `var(--${color})`, height: disabled ? "100%" : buttonSize === "small" ? "97px" : "122px", transform: isPressed ? 'translateY(8px)' : 'translateY(0)', transition: "0.1s", zIndex: 3, color: `var(--${color === "primary" ? "white" : "font"})` }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            onClick={onClick}
            id={id}
          >
            <span className={`m-0 p-0 ${icon ? "me-3" : ""}`}>{(icon && iconCenter) && icon}</span>
            {label}
            {(icon && !iconCenter) &&
              <div className='m-0 p-0 position-absolute' style={{ width: "fit-content", height: "fit-content", left: 40 }}>
                {icon}
              </div>
            }
            {
              arrow &&
              <div className='m-0 p-0 position-absolute' style={{ width: "fit-content", height: "fit-content", right: 40 }}>
                {arrow}
              </div>
            }
          </Button>
          <div className='position-absolute border-radius overflow-hidden'
            style={{
              zIndex: 1,
              bottom: "0px",
              height: disabled ? "0px" : buttonSize === "small" ? "97px" : "122px",
              background: `var(--shadowCustomButton${color === "primary" ? "Secondary" : timer ? "Timer" : "Primary"})`
            }}
          >
            {(isTiming && delay && timer) && (
              <div
                className='position-absolute border-radius timerAnim'
                id="timer"
                style={{
                  zIndex: 2,
                  bottom: '0px',
                  height: '100%',
                  background: color === "primary" ? `var(--${theme === "lightTheme" ? "font" : "white"})` : "var(--darkRose)",
                  width: "100%",
                  left: `-${(timeLeft / delay) * 100}%`,
                  transition: "left 0.1s linear"
                }}
              />
            )}
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default CustomButton;
