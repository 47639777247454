import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

// ** fetch settings
export const fetchSettings = createAsyncThunk('marketplace/fetchSettings', async (params) => {
  const response = await api.get(`api/settings/list?type=${params?.type}`)
  return response?.data
})

// ** fetch prices
export const fetchPrices = createAsyncThunk('marketplace/fetchPrices', async (params) => {
  const response = await api.get(`api/marketplace/price/list?type=${params?.type}${params?.code ? `&code=${params?.code}` : ""}`)
  return response?.data
})

const initialState = {
  settings: false,
  pricePacks: false,
}

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    resetMarketPlaceState: () => initialState,
    resetPrices: (state) => {
      state.pricePacks = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.settings = action.payload.Data
    })
    builder.addCase(fetchPrices.fulfilled, (state, action) => {
      state.pricePacks = action.payload.Data
    })
  }
})

export const { resetMarketPlaceState,resetPrices } = marketplaceSlice.actions;
export default marketplaceSlice.reducer


