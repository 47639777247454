import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import NavigationTitle from 'components/common/navigationTitle';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronDown } from 'assets/images/icons/chevronDown.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash.svg';
import { updateLoading, updateNotificationsModal } from 'store/apps/appConfig';
import { readUserNotification, deleteUserNotification, fetchUserNotifications } from 'store/apps/notifications';
import { notifications } from 'config/notifications';
import { ImNotification } from "react-icons/im";
import { ReactComponent as ChevronDownScroll } from 'assets/images/icons/chevronDownScroll.svg'
import CustomButton from '../buttons/customButton';


const NotificationsModal = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState('');
  const [deleteErrorId, setDeleteErrorId] = useState(false);
  const { prefix: numberPrefix, number } = useSelector(state => state.user);
  const { Data: notificationsInfo } = useSelector(state => state.notifications);
  const { theme } = useSelector(state => state.appConfig);
  const [hasScroll, setHasScroll] = useState(false);

  const toggle = (id) => {
    setOpen(prevOpen => (prevOpen === id ? '' : id));
  };


  const readNotification = (id, rowId) => {
    dispatch(readUserNotification({
      number: number,
      numberPrefix: numberPrefix,
      notificationId: id,
      readed: 1,
      rowId: rowId
    }))
  }

  const deleteNotification = (e, rowId) => {
    e.stopPropagation();
    dispatch(updateLoading(true));
    if (!deleteErrorId) {
      dispatch(deleteUserNotification(rowId))
        .then((res) => {
          if (res?.payload?.Result === 1) {
            dispatch(fetchUserNotifications({ number: number, numberPrefix: numberPrefix }))
              .then(() => {
                dispatch(updateLoading(false));
              })
          }
          else {
            dispatch(updateLoading(false));
            setDeleteErrorId(rowId);
          }
        })
    }
  }

  const hasVerticalScrollbar = (element) => {
    if (element) {
      return element.scrollHeight > element.clientHeight;
    }
  }

  const scrollToTop = () => {
    const scrollDiv = document.getElementById('scroll');
    if (scrollDiv) {
      const scrollStep = 150;
      scrollDiv.scrollTo({
        top: scrollDiv.scrollTop + scrollStep,
        behavior: "smooth"
      });
    }
  };

  const scrollToBottom = () => {
    const scrollDiv = document.getElementById('scroll');
    if (scrollDiv) {
      const scrollStep = -150;
      scrollDiv.scrollTo({
        top: scrollDiv.scrollTop + scrollStep,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollDiv = document.getElementById('scroll');
      if (scrollDiv && hasVerticalScrollbar(scrollDiv)) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    };

    const resizeObserver = new ResizeObserver(handleScroll);
    const scrollDiv = document.getElementById('scroll');

    if (scrollDiv) {
      scrollDiv.addEventListener('scroll', handleScroll);
      resizeObserver.observe(scrollDiv);

      return () => {
        scrollDiv.removeEventListener('scroll', handleScroll);
        resizeObserver.unobserve(scrollDiv);
      };
    }
  }, [notificationsInfo]);

  useEffect(() => {
    if (deleteErrorId) {
      setTimeout(() => {
        setDeleteErrorId(false);
      }, [2000])
    }
  }, [deleteErrorId])

  return (
    <Row className='h-100 m-0'>
      <Col xs="12" style={{ height: 100 }}>
        <NavigationTitle title={t(`app.notifications`)} modal />
      </Col>
      <Col xs="12" style={{ height: "calc(100% - 330px)" }} className="py-4 position-relative">
        {hasScroll &&
          <>
            <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ left: "calc(50% - 50px)",width: 100, height: 100, top: 15, transform: "rotate(180deg)", stroke: `var(${theme === "lightTheme" ? "--font" : "--white"})` }} onClick={() => scrollToBottom()}>
              <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{ stroke: `var(${theme === "lightTheme" ? "--font" : "--white"})` }} />
            </div>
            <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ left:"calc(50% - 50px)",width: 100, height: 100, bottom:-75, stroke: `var(${theme === "lightTheme" ? "--font" : "--white"})` }} id="scrollDown" onClick={() => scrollToTop()}>
              <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{ stroke: `var(${theme === "lightTheme" ? "--font" : "--white"})` }} />
            </div>
          </>
        }
        <Row className='h-100 py-5'>
          {
            notificationsInfo && notificationsInfo?.length > 0 ?
              <Accordion flush open={open} toggle={toggle} className="overflow-auto customScroll h-100 mt-5 px-2" id="scroll">
                {
                  notificationsInfo && notificationsInfo.map((item, index) => (
                    <div
                      key={index}
                      className={`${index === 0 ? "mt-5 mb-4" : index === notificationsInfo?.length - 1 ? "mt-4" : "my-4"} ${deleteErrorId === item?.rowId ? "error" : ""}`}
                      style={{ borderRadius: 10, border: `1px solid ${deleteErrorId === item?.rowId ? "var(--erro)" : "transparent"}` }}
                    >
                      <AccordionItem
                        onClick={!item?.readed ? () => readNotification(item?.id, item?.rowId) : null}>
                        <AccordionHeader targetId={`${index + 1}`}>
                          <Col xs="10">
                            <Row className='d-flex align-items-center ps-3 py-2'>
                              <span className='m-0 p-0 w-fit-content text-3' style={{ color: notifications[item?.type] }}>{item?.readed ? "○" : "●"}</span>
                              <span className='m-0 w-fit-content p-0 ps-4 text-4' style={{ overflowWrap: "break-word" }}>
                                {item?.subject[i18n.language]}
                              </span>
                            </Row>
                          </Col>
                          <Col xs="2">
                            <Row className='d-flex justify-content-end align-items-center pe-3'>
                              <Trash style={{ width: 30, height: "auto" }} className="m-0 p-0 me-4" onClick={(e) => deleteNotification(e, item?.rowId)} />
                              <ChevronDown style={{ width: 25, height: "auto", transform: `rotate(${open === `${index + 1}` ? "180deg" : "0"})`, transition: "0.3s all" }} className="m-0 p-0" />
                            </Row>
                          </Col>
                        </AccordionHeader>
                        <AccordionBody accordionId={`${index + 1}`}>
                          <Row className='px-4'>
                            <span className='m-0 p-0 text-5' style={{ wordBreak: "break-word" }}>
                              {item?.text[i18n.language]}
                            </span>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                    </div>
                  ))
                }
              </Accordion>
              :
              <Col xs="12" className='pt-5'>
                <Row className='h-100 d-flex justify-content-center align-items-center' style={{ color: theme === "lightTheme" ? "var(--font)" : "var(--white)" }}>
                  <div className='m-0 p-0 w-auto h-auto d-flex flex-column'>
                    <ImNotification size="75" className="w-auto mb-4" />
                    <span className='m-0 p-0 w-auto h-auto mt-4 text-4'>
                      {t('app.noLockersAssociated')}
                    </span>
                  </div>
                </Row>
              </Col>
          }
        </Row>
      </Col>
      <Col xs="12" style={{ height: 'fit-content' }}>
        <Row className='h-100 d-flex'>
          <Col xs="12" style={{ height: 100 }} />
          <Col xs="12">
            <CustomButton
              timer
              modal
              color="secondary"
              label={t("app.back")}
              textSize="text-3"
              onClick={() => dispatch(updateNotificationsModal(false))}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default NotificationsModal;
