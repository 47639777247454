import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { fetchFaqs, updateFaqsLanguage, updateLoading } from 'store/apps/appConfig';
import { useTranslation } from 'react-i18next';
import { translationFlags } from 'config/translationFlags';
import { ReactComponent as ChevronDown } from 'assets/images/icons/chevronDown.svg';
import { ReactComponent as ChevronDownScroll } from 'assets/images/icons/chevronDownScroll.svg'
import { useDispatch, useSelector } from 'react-redux';
import CustomCard from '../cards/customCard';
import NavigationTitle from '../navigationTitle';
import CustomButton from '../buttons/customButton';

const FaqsModal = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState('');
  const [faqs, setFaqs] = useState(false);
  const [columnScroll, setColumnScroll] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const { theme } = useSelector(state => state.appConfig)

  const toggle = (id) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const changeCountry = (country) => {
    i18n.changeLanguage(country?.code);
  }

  const hasVerticalScrollbar = (element) => {
    if (element) {
      return element.scrollHeight > element.clientHeight;
    }
  }

  const scrollToTop = () => {
    const accordionElement = document.getElementById('faqs');
    if (accordionElement) {
      const scrollStep = 150;
      const currentPosition = accordionElement.scrollTop;
      accordionElement.scrollTo({
        top: currentPosition + scrollStep,
        behavior: 'smooth'
      });
    }
  };

  const scrollToBottom = () => {
    const accordionElement = document.getElementById('faqs');
    if (accordionElement) {
      const scrollStep = -150;
      const currentPosition = accordionElement.scrollTop;
      accordionElement.scrollTo({
        top: currentPosition + scrollStep,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    setColumnScroll(document.getElementById("columnScrollFaqs")?.clientHeight)
  }, [columnScroll])

  useEffect(() => {
    dispatch(updateLoading(true));
    dispatch(fetchFaqs())
      .then((res) => {
        if (res.payload.Result === 1) {
          dispatch(updateLoading(false));
          setFaqs(res.payload?.Data);
        }
        else {
          console.log(res)
          dispatch(updateLoading(false));
        }
      })
  }, [dispatch])

  useEffect(() => {
    const handleScroll = () => {
      const scrollDiv = document.getElementById('faqs');
      if (scrollDiv && hasVerticalScrollbar(scrollDiv)) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    };

    const resizeObserver = new ResizeObserver(handleScroll);
    const scrollDiv = document.getElementById('faqs');

    if (scrollDiv) {
      scrollDiv.addEventListener('faqs', handleScroll);
      resizeObserver.observe(scrollDiv);

      return () => {
        scrollDiv.removeEventListener('faqs', handleScroll);
        resizeObserver.unobserve(scrollDiv);
      };
    }
  }, [faqs]);


  return (
    <Row className='m-0 h-100'>
      <Col xs="12" style={{ height: 100 }}>
        <NavigationTitle title={t('app.helpAndFaqs')} modal />
      </Col>
      <Col xs="12" style={{ height: "calc(100% - 230px)" }}>
        <Row style={{ height: "30%" }} className="m-0 d-flex align-items-center">
          {translationFlags.map((item, index) =>
            <Col key={index} xs="3" onClick={() => changeCountry(item)} className="d-flex justify-content-center">
              <div className='m-0 p-0' style={{ width: 120, height: 115 }}>
                <CustomCard color={i18n.language === item?.code ? "lightRose" : "secondary"}>
                  <div className='m-0 p-0' style={{ width: 100 }}>{item?.flag}</div>
                </CustomCard>
              </div>
            </Col>
          )}
        </Row>
        <Row id="columnScrollFaqs" style={{ height: "60%" }}>
          <Col xs="12">
            <Row className='h-100 position-relative d-flex justify-content-center'>
              {hasScroll &&
                <>
                  <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ width: 100, height: 100, top: -100, transform: "rotate(180deg)" }} onClick={() => scrollToBottom()}>
                    <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{stroke: theme === "lightTheme" ? "var(--font)" : "var(--white)"}} />
                  </div>
                  <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ width: 100, height: 100, bottom: -100 }} id="scrollDown" onClick={() => scrollToTop()}>
                    <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{stroke: theme === "lightTheme" ? "var(--font)" : "var(--white)"}} />
                  </div>
                </>
              }
              <Accordion flush open={open} toggle={toggle} className={`overflow-auto customScroll ${hasScroll && "pe-3"}`} id="faqs" style={{ height: columnScroll }}>
                {
                  faqs && faqs.map((item, index) => (
                    <AccordionItem className={`px-3 ${index === 0 ? "mb-5 py-4" : index === faqs?.length - 1 ? "mt-5 py-4" : "my-5 py-4"}`} key={index}>
                      <AccordionHeader targetId={`${item?.id}`}>
                        <Col xs="11">
                          <Row>
                            <span className='p-0 ps-1 text-5' style={{ overflowWrap: "break-word" }}>
                              {item?.title[i18n.language]}
                            </span>
                          </Row>
                        </Col>
                        <Col xs="1">
                          <Row className='d-flex justify-content-end align-items-center pe-1'>
                            <ChevronDown style={{ width: 25, height: "auto", transform: `rotate(${open === `${item?.id}` ? "180deg" : "0"})`, transition: "0.3s all" }} className="m-0 p-0" />
                          </Row>
                        </Col>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${item?.id}`}>
                        <Row className='px-3'>
                          <span className='m-0 p-0 text-5' style={{ textAlign: "justify" }}>
                            {item?.text[i18n.language]}
                          </span>
                        </Row>
                      </AccordionBody>
                    </AccordionItem>
                  ))
                }
              </Accordion>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs="12" style={{ height: 130 }}>
        <Row className='h-100 d-flex'>
          <Col xs="12">
            <CustomButton
              timer
              modal
              color="secondary"
              label={t("app.back")}
              textSize="text-3"
              onClick={() => dispatch(updateFaqsLanguage(false))}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FaqsModal;
